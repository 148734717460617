<template>
  <manager v-loading="loading" :referee_data="referee_data" view_type="edit"></manager>
</template>

<script>
import manager from "./manager";
import {mapActions} from "vuex";
export default {
  name: "edit",
  components: {
    manager
  },
  data() {
    return {
      type: 'referee',
      referee_data: {},
      loading: true,
    };
  },
  async mounted() {
    this.loading = true;
    let _id = this.$route.query._id;
    if(_id) {
      let { data, errorCode } = await this.$http.armory.detail(_id, this.type);
      if (errorCode != "0000") {
        this.removeTabByPath(`/armory/${this.type}/add`);
        this.removeTabByPath(`/armory/${this.type}/edit`);
        return;
      }

      this.referee_data = data;
    } else {
      this.removeTabByPath(`/armory/${this.type}/add`);
      this.removeTabByPath(`/armory/${this.type}/edit`);
    }

    this.loading = false;
  },
  methods: {
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ])
  }
}
</script>

<style scoped>

</style>
